import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Button, NavDropdown } from 'react-bootstrap';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { FaFacebookSquare, FaLinkedin, FaPhoneAlt, FaInstagramSquare } from "react-icons/fa";

import './scss/style.scss';
import 'bootstrap/dist/css/bootstrap.css';
import "animate.css/animate.min.css";

import Home from './pages/Home';
import Software from './pages/Software';
import Hardware from './pages/Hardware';
import Solution from './pages/Solution';
import ProductDetail from './pages/ProductDetail';
import About from './pages/About';
import Contact from './pages/Contact';
import Catalogue from './pages/Catalogue';
import PageNotFound from './pages/PageNotFound';

import burgermenu from './assets/burger_menu_white.png'
import ScrollButton from './components/ScrollButton';
import logo_text_white from './assets/logo-text-white.png'
import logo from "./assets/logo.png"

const Header = () => {
  const location = useLocation()

  return(
    <div className='sticky-top'>
      <div className='navbar-top'>
        <Container>
          <div className='d-flex flex-row justify-content-between align-items-center gap-3 pt-2 pb-2'>
            <div className='d-flex flex-row align-items-center gap-2'>
              <FaFacebookSquare size={18}/>
              <FaLinkedin size={18}/>
              <FaInstagramSquare size={18}/>
            </div>
            <div>Opening Hours : Monday to Friday - 9am to 6pm</div>
          </div>
        </Container>
      </div>
      <Navbar collapseOnSelect expand="lg">
        <Container>
          <Navbar.Brand href="/">
            <img src={logo_text_white} className="logo-main" allt=""/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav">
            <img src={burgermenu} alt="" className='burger-menu'/>
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto align-items-center gap-4">
              <Nav.Link href="/" className={[location.pathname==='/'?'active':'']}>Home</Nav.Link>
              {/* <NavDropdown title="Product" className={[location.pathname.indexOf('hardware') > -1 || location.pathname.indexOf('software') > -1?'active':'']}>
                <NavDropdown.Item href="/product/hardware" className={[location.pathname.indexOf('hardware') > -1?'active':'']}>Hardware</NavDropdown.Item>
                <NavDropdown.Item href="/product/software" className={[location.pathname.indexOf('software') > -1?'active':'']}>Software</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/solution" className={[location.pathname.indexOf('solution') > -1?'active':'']}>Solution</Nav.Link> */}
              <Nav.Link href="/catalogue" className={[location.pathname.indexOf('catalogue') > -1?'active':'']}>Catalogue</Nav.Link>
              <Nav.Link href="/about-us" className={[location.pathname.indexOf('about-us') > -1?'active':'']}>About Us</Nav.Link>
              <a href="https://wa.me/6285959541000" target="_blank" className="any-question d-flex flex-row align-items-center gap-2">
                <FaPhoneAlt size={24} color='#fff'/>
                <div className="flex-fill">
                  <div className="ask">Have Any Question?</div>
                  <div className="phone">+6221 22958146</div>
                </div>
              </a>
              <Nav.Link href="/contact-us">
                <Button variant='brand-secondary'>FREE QUOTE</Button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

const Footer = () => {
  return(
    <div className='footer'>
      <Container className='pt-5 pb-5'>
        <div>
          <img src={logo_text_white} alt="" style={{width:120, marginTop:-30}}/>
        </div>
        <div className='mt-4 d-flex flex-row flex-row-mobile'>
          <div className='flex-grow-1'>
            <div className='text-white' style={{maxWidth:400}}>Centennial Tower, 38’th floor, Unit H Jl. Gatot Subroto, kavling 24-25, Jakarta Selatan, 12930, Indonesia</div>
          </div>
        </div>
        <div className='hr-white mt-4 mb-4'/>
        <div className='text-white'>© {new Date().getFullYear()} All rights reserved</div>
      </Container>
    </div>
  )
}

const PageLayout = (page) => {
  return(
    <>
      <Header/>
      {page}
      <Footer/>
    </>
  )
}

function App() {
  
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={PageLayout(<Home/>)}/>
        <Route path='/product/hardware' element={PageLayout(<Hardware/>)}/>
        <Route path='/product/software' element={PageLayout(<Software/>)}/>
        <Route path='/solution' element={PageLayout(<Solution/>)}/>
        <Route path='/catalogue/:slug' element={PageLayout(<ProductDetail/>)}/>
        <Route path='/catalogue' element={PageLayout(<Catalogue/>)}/>
        <Route path='/about-us' element={PageLayout(<About/>)}/>
        <Route path='/contact-us' element={PageLayout(<Contact/>)}/>
        <Route path='*' element={<PageNotFound/>}/>
      </Routes>
      <ScrollButton/>
    </BrowserRouter>
  );
}

export default App;
